import service from '@/utils/service'

// 精选专题列表
export const getHotTopicList = (params) =>
  service({
    url: '/api/home/wonderfulTopic',
    method: 'get',
    params,
  })

// 精选专题列表new
export const getHotTopicListNew = (params) =>
  service({
    url: '/api/home/topicList',
    method: 'get',
    params,
  })

// 获取专题成员列表
export const getTopicMemberList = (params) =>
  service({
    url: '/api/topic/getTopicUserList',
    method: 'get',
    params,
  })

// 添加专题成员
export const addTopicMember = (params) =>
  service({
    url: '/api/topic/insertTopicUser',
    method: 'post',
    params,
  })

// 删除专题成员
export const deleteTopicMember = (params) =>
  service({
    url: '/api/topic/delTopicUser',
    method: 'post',
    params,
  })

// 专题-批量收藏
export const batchCollectTopic = (params) =>
  service({
    url: '/api/personalCenter/topicCollect',
    method: 'get',
    params,
  })

// 专题-批量取消收藏
export const batchCancelCollectTopic = (params) =>
  service({
    url: '/api/personalCenter/cancelCollectTopic',
    method: 'get',
    params,
  })

// 专题-下载弹窗详情
export const getTopicDownloadDetail = (params) =>
  service({
    url: '/api/topic/topicDetailByForm',
    method: 'get',
    params,
  })

// 专题-创建
export const createTopic = (data) =>
  service({
    url: '/api/topic/insertTopic',
    method: 'post',
    data,
  })

// 专题-详情
export const getTopicDetail = (params) =>
  service({
    url: '/api/topic/topicDetails',
    method: 'get',
    params,
  })

// 审核专题-驳回、通过
export const checkTopic = (params) =>
  service({
    url: '/api/topic/topicCheck',
    method: 'get',
    params,
  })

// 专题-发布、下架
export const topicReleaseOrClose = (params) =>
  service({
    url: '/api/topic/topicReleaseOrClose',
    method: 'get',
    params,
  })

// 专题-撤回审核
export const cancelTopicCheck = (params) =>
  service({
    url: '/api/topic/cancelTopicVerify',
    method: 'get',
    params,
  })

// 专题-查看审核流程
export const topicCheckProcess = (params) =>
  service({
    url: '/api/topic/getTopicVerifyDetails',
    method: 'get',
    params,
  })

// 专题-根据专题名称模糊搜索
export const topicNameLikeSearch = (params) =>
  service({
    url: '/api/topic/topicNameLikeSearch',
    method: 'get',
    params,
  })

// 专题-获取最新的10条
export const getNewestTopic = (params) =>
  service({
    url: '/api/topic/getNewestTopic',
    method: 'get',
    params,
  })

// 专题–文件上传
export const submitFile = (data) =>
  service({
    url: '/api/upload/submitFile',
    method: 'post',
    data,
  })

// 专题-编辑页面详情
export const getEditTopicDetail = (params) =>
  service({
    url: '/api/topic/getEditTopicDetails',
    method: 'get',
    params,
  })

// 专题-编辑
export const editTopic = (data) =>
  service({
    url: '/api/topic/editTopic',
    method: 'post',
    data,
  })

// 获取专题下所有标签
export const getTopicTagList = (params) =>
  service({
    url: '/api/tag/getTopicTagList',
    method: 'get',
    params,
  })

// 我的创建
export const myCreatedTopic = (params) =>
  service({
    url: '/api/personalCenter/myCreatedTopic',
    method: 'get',
    params,
  })

// 我的参与
export const myJoinedTopic = (params) =>
  service({
    url: '/api/personalCenter/myJoinTopic',
    method: 'get',
    params,
  })

// 待审核的
export const notReviewTopic = (params) =>
  service({
    url: '/api/personalCenter/waitAuditTopic',
    method: 'get',
    params,
  })

// 我审核的
export const myReviewTopic = (params) =>
  service({
    url: '/api/personalCenter/myAuditTopic',
    method: 'get',
    params,
  })

// 我收藏的-专题
export const myCollectionTopic = (params) =>
  service({
    url: '/api/personalCenter/myCollectTopic',
    method: 'get',
    params,
  })

// 我喜欢的-专题
export const myLikeTopicApi = (params) =>
  service({
    url: '/api/likeInfo/myLikeTopic',
    method: 'get',
    params,
  })

// 我的下载-专题
export const myDownloadedTopic = (params) =>
  service({
    url: '/api/personalCenter/myDownLoadTopic',
    method: 'get',
    params,
  })

// 批量删除专题
export const batchDeleteTopic = (params) =>
  service({
    url: '/api/topic/batchDelTopic',
    method: 'get',
    params,
  })

// 我的下载-批量删除
export const myDownloadBatchDelete = (params) =>
  service({
    url: '/api/personalCenter/delMyDownLoadBatch',
    method: 'get',
    params,
  })

export const saveIsLikeApi = (data) =>
  service({
    url: '/api/likeInfo/saveIsLike',
    method: 'post',
    data,
  })
