import service from '@/utils/service'

// 专题-批量压缩下载-步骤1
export const topicDownloadType1 = (params) =>
  service({
    url: '/api/download/fileBatchDownloadByType',
    method: 'get',
    params,
  })

// 专题-批量压缩下载-步骤2
export const topicDownloadType2 = (params) =>
  service({
    url: '/api/download/fileBatchDownloadByType2',
    method: 'get',
    params,
    responseType: 'blob',
    onDownloadProgress: function (event) {
      if (event.total) {
        // let progress = (event.loaded / event.total) * 100
      }
    },
  })

// 文件-下载数量+1
export const fileDownloadNumAdd = (params) =>
  service({
    url: '/api/download/fileDownloadNumAdd',
    method: 'get',
    params,
  })

// 专题-下载数量+1
export const topicDownloadNumAdd = (params) =>
  service({
    url: '/api/topic/topicFileDownloadNumAdd',
    method: 'get',
    params,
  })

// 批量专题文件混合下载第一步-同步
export const personalCenterBatchDownload = (data) =>
  service({
    url: '/api/personalCenter/fileBatchDownload',
    method: 'post',
    data,
  })

// 批量专题文件混合下载第一步-异步
export const personalCenterBatchDownloadSync = (data) =>
  service({
    url: '/api/personalCenter/fileBatchDownloadSync',
    method: 'post',
    data,
  })

// 专题整体打包下载获取URL，根据status判断是否正在生成新的压缩包
export const getTopicPackUrl = (params) =>
  service({
    url: '/api/topic/getTopicPackUrl',
    method: 'get',
    params,
  })

export const downloadTestApi = (params) =>
  service({
    url: '/api/file/downloadTest',
    method: 'get',
    params,
  })
