import service from '@/utils/service'

// 获取一级二级地区接口
export const getTreeDictItemByLogin = (params) =>
  service({
    baseURL: process.env.VUE_APP_BASE_ADMIN_API_URL,
    url: '/api/dictionary/getTreeDictItemByLogin',
    method: 'get',
    params,
  })

// 获取部门
export const getDepartmentList = (data) =>
  service({
    baseURL: process.env.VUE_APP_BASE_ADMIN_API_URL,
    url: '/api/department/listAll',
    method: 'post',
    data,
  })

// 根据ip获取地理位置
export const getDataByIP = () =>
  service({
    url: '/api/user/getDataByIP',
    method: 'get',
  })

// 获取字典树形结构数据 dict-1: 类别 dict-2: 用途 dict-3: 地区 dict-4: 大区
export const getTreeDictItem = (data) =>
  service({
    baseURL: process.env.VUE_APP_BASE_ADMIN_API_URL,
    url: '/api/dictionary/getTreeDictItem',
    method: 'post',
    data,
  })

// 同步渠道列表
export const getSyncChannelList = (params) =>
  service({
    url: '/api/channel/listSyncChannel',
    method: 'get',
    params,
  })

// 关联文件树形结构
export const getRelevantTree = (params) =>
  service({
    url: '/api/file/getRelevantTree',
    method: 'get',
    params,
  })

// 批量收藏-文件或专题
export const batchCollect = (data) =>
  service({
    url: '/api/personalCenter/batchCollect',
    method: 'post',
    data,
  })

export const getOutTokenApi = () =>
  service({
    url: '/dam/getOutToken',
    method: 'get',
  })
