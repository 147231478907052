import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zhCN from './lang/zh-CN.json'
import enUS from './lang/en-US.json'
import frFR from './lang/fr-FR.json'
import esES from './lang/es-ES.json'
import ptBR from './lang/pt-BR.json'
import arEG from './lang/ar-EG.json'
import deDE from './lang/de-DE.json'
import ruRU from './lang/ru-RU.json'
import zhTH from './lang/zh-TH.json'
import idcDesignEn from '@idc-design/web-ui/es/locale/en_US.js'
import idcDesignZh from '@idc-design/web-ui/es/locale/zh_CN.js'
import idcDesignFr from '@idc-design/web-ui/es/locale/fr_FR.js'
import idcDesignEs from '@idc-design/web-ui/es/locale/es_ES.js'
import idcDesignPt from '@idc-design/web-ui/es/locale/pt_BR.js'
// import idcDesignAr from '@idc-design/web-ui/es/locale/ar_EG.js'
import idcDesignDe from '@idc-design/web-ui/es/locale/de_DE.js'
import idcDesignRu from '@idc-design/web-ui/es/locale/ru_RU.js'
import idcDesignTh from '@idc-design/web-ui/es/locale/th_TH.js'
import 'moment/dist/locale/zh-cn'
import { useSystemStore } from '@/store/modules/system'
import storage from 'store'
import moment from 'moment'
moment.locale('zh-cn')

Vue.use(VueI18n)

export const LANGUAGE_EMNU = [
  // 中文
  {
    key: 'zh-CN',
    value: zhCN,
    momentKey: 'zh-cn',
    antKey: 'zh_CN',
    antValue: JSON.parse(JSON.stringify(idcDesignZh)),
    name: '中文-简体',
  },
  // 英文
  {
    key: 'en-US',
    value: enUS,
    momentKey: 'es-us',
    antKey: 'en_US',
    antValue: JSON.parse(JSON.stringify(idcDesignEn)),
    name: 'English',
  },
  // 法语
  {
    key: 'fr-FR',
    value: frFR,
    momentKey: 'fr-fr',
    antKey: 'fr_FR',
    antValue: JSON.parse(JSON.stringify(idcDesignFr)),
    name: 'Français',
  },
  // 西班牙语
  {
    key: 'es-ES',
    value: esES,
    momentKey: 'es-es',
    antKey: 'es_ES',
    antValue: JSON.parse(JSON.stringify(idcDesignEs)),
    name: 'Español',
  },
  // 葡萄牙语
  {
    key: 'pt-BR',
    value: ptBR,
    momentKey: 'pt-br',
    antKey: 'pt_BR',
    antValue: JSON.parse(JSON.stringify(idcDesignPt)),
    name: 'Português',
  },
  // 阿拉伯语
  {
    key: 'ar-EG',
    value: arEG,
    momentKey: 'ar-eg',
    antKey: 'ar_EG',
    antValue: JSON.parse(JSON.stringify(idcDesignEn)),
    name: 'بالعربية',
  },
  // 德语
  {
    key: 'de-DE',
    value: deDE,
    momentKey: 'de-de',
    antKey: 'de_DE',
    antValue: JSON.parse(JSON.stringify(idcDesignDe)),
    name: 'Deutsch',
  },
  // 俄语
  {
    key: 'ru-RU',
    value: ruRU,
    momentKey: 'ru-ru',
    antKey: 'ru_RU',
    antValue: JSON.parse(JSON.stringify(idcDesignRu)),
    name: 'Русский язык',
  },
  // 泰语
  {
    key: 'zh-TH',
    value: zhTH,
    momentKey: 'zh-th',
    antKey: 'zh_TH',
    antValue: JSON.parse(JSON.stringify(idcDesignTh)),
    name: 'ภาษาไทย',
  },
]

export const defaultLang = storage.get('language')?.key || 'zh-CN'
export const defaultLangObj = LANGUAGE_EMNU.find((item) => item.key === defaultLang)

const messages = {
  'zh-CN': {
    ...zhCN,
  },
  'en-US': {
    ...enUS,
  },
  'fr-FR': {
    ...frFR,
  },
  'es-ES': {
    ...esES,
  },
  'pt-BR': {
    ...ptBR,
  },
  'ar-EG': {
    ...arEG,
  },
  'de-DE': {
    ...deDE,
  },
  'ru-RU': {
    ...ruRU,
  },
  'zh-TH': {
    ...zhTH,
  },
}

const i18n = new VueI18n({
  silentTranslationWarn: true,
  locale: defaultLang,
  fallbackLocale: defaultLang,
  messages,
})

setI18nLanguage(defaultLang)

export function i18nRender(key) {
  return i18n.t(key)
}

export function setI18nLanguage(lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
}

export function loadLanguageAsync(lang) {
  // 更新 store
  const langObj = LANGUAGE_EMNU.find((item) => item.key === lang)
  const systemStore = useSystemStore()
  systemStore.updateData({ language: langObj })
  storage.set('language', langObj)
  const locale = langObj.value
  i18n.setLocaleMessage(lang, locale)
  moment.updateLocale(langObj.momentKey, langObj.momentKey)
  setI18nLanguage(lang)
}

export default i18n
