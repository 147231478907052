import service from '@/utils/service'

// 搜索全部new
export const searchAllNew = (params) =>
  service({
    url: '/api/home/findAllList',
    method: 'get',
    params,
  })

// 搜索全部
export const searchAll = (data) =>
  service({
    url: '/api/search/searchAllList',
    method: 'post',
    data,
  })

// 搜索专题
export const searchTopic = (data) =>
  service({
    url: '/api/search/searchTopic',
    method: 'post',
    data,
  })

// 搜索图片和视频
export const searchImageVideo = (data) =>
  service({
    url: '/api/search/imageList',
    method: 'post',
    data,
  })

// 搜索文档和源文件
export const searchDocumentSourceFile = (data) =>
  service({
    url: '/api/search/docList',
    method: 'post',
    data,
  })

export const getImageResultApi = (data) =>
  service({
    url: '/api/aiInterface/getImageResult',
    method: 'post',
    data,
  })