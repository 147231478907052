import service from '@/utils/service'

// 登录接口
export const userLogin = (params) =>
  service({
    url: '/api/user/login',
    method: 'post',
    params,
  })

// 根据钉钉 code 获取 token
export const getTokenByCodeSync = (params) =>
  service({
    url: '/api/user/getTokenByAuthCode',
    method: 'get',
    params,
  })

// 登出接口
export const logout = () =>
  service({
    url: '/api/user/logout',
    method: 'post',
  })

export const getAllLoginBackgroundApi = () =>
  service({
    baseURL: process.env.VUE_APP_BASE_ADMIN_API_URL,
    url: '/api/loginBackground/getAllLoginBackground',
    method: 'get',
  })
