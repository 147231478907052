import service from '@/utils/service'

// 精选图片/视频列表
export const getHotImgAndVideoList = (params) =>
  service({
    url: '/api/home/hotList',
    method: 'get',
    params,
  })

// 精选图片/视频列表new
export const getHotImgAndVideoListNew = (params) =>
  service({
    url: '/api/home/resourceList',
    method: 'get',
    params,
  })

// 文件详情
export const getFileDetail = (params) =>
  service({
    url: '/api/search/fileDetail',
    method: 'get',
    params,
  })

// 文件轨迹
export const getTraceList = (data) =>
  service({
    url: '/api/search/traceList',
    method: 'post',
    data,
  })

// 删除专题下的文件
export const deleteTopicFile = (params) =>
  service({
    url: '/api/topic/deleteTopicFile',
    method: 'get',
    params,
  })

// 文件编辑
export const editFile = (data) =>
  service({
    url: '/api/file/updateFile',
    method: 'post',
    data,
  })

// 文件-批量收藏
export const batchCollectFile = (params) =>
  service({
    url: '/api/search/collect',
    method: 'get',
    params,
  })

// 文件-单个取消收藏
export const singleCancelCollectFile = (params) =>
  service({
    url: '/api/search/cancelCollect',
    method: 'get',
    params,
  })

// 文件-下载弹窗详情
export const getFileDownloadDetail = (params) =>
  service({
    url: '/api/search/getRelevantFileList',
    method: 'get',
    params,
  })

// 专题详情获取图片和视频
export const getTopicImageOrVideo = (params) =>
  service({
    url: '/api/topic/detailImageList',
    method: 'get',
    params,
  })

// 专题详情获取文档列表
export const getTopicDocument = (params) =>
  service({
    url: '/api/topic/detailDocList',
    method: 'get',
    params,
  })

// 专题详情获取源文件列表
export const getTopicSourceFile = (params) =>
  service({
    url: '/api/topic/detailAllOriginList',
    method: 'get',
    params,
  })

// 专题详情获取全部文件
export const getTopicAllContent = (params) =>
  service({
    url: '/api/topic/detailAllList',
    method: 'get',
    params,
  })

// 我的收藏-图片和视频
export const myCollectedImageAndVideo = (params) =>
  service({
    url: '/api/personalCenter/myCollectList',
    method: 'get',
    params,
  })

// 我的收藏-文档
export const myCollectedDocList = (params) =>
  service({
    url: '/api/personalCenter/myCollectDocList',
    method: 'get',
    params,
  })

// 我的收藏-源文件
export const myCollectedSourceFileList = (params) =>
  service({
    url: '/api/personalCenter/myCollectOriginList',
    method: 'get',
    params,
  })

// 我的喜欢-图片和视频
export const myLikeImageVideoApi = (params) =>
  service({
    url: '/api/likeInfo/myLikeImageVideo',
    method: 'get',
    params,
  })

// 我的喜欢-文档
export const myLikeDocListApi = (params) =>
  service({
    url: '/api/likeInfo/myLikeDocList',
    method: 'get',
    params,
  })

// 我的喜欢-源文件
export const myLikeOriginListApi = (params) =>
  service({
    url: '/api/likeInfo/myLikeOriginList',
    method: 'get',
    params,
  })

// 我的上传-图片和视频
export const myUploadedImageAndVideo = (params) =>
  service({
    url: '/api/personalCenter/uploadList',
    method: 'get',
    params,
  })

// 我的上传-文档
export const myUploadedDocList = (params) =>
  service({
    url: '/api/personalCenter/uploadDocList',
    method: 'get',
    params,
  })

// 我的上传-源文件
export const myUploadedSourceFileList = (params) =>
  service({
    url: '/api/personalCenter/uploadOriginList',
    method: 'get',
    params,
  })

// 我的下载-图片和视频
export const myDownloadedImageAndVideo = (params) =>
  service({
    url: '/api/personalCenter/dowmloadList',
    method: 'get',
    params,
  })

// 我的下载-文档
export const myDownloadedDocList = (params) =>
  service({
    url: '/api/personalCenter/dowmloadDocList',
    method: 'get',
    params,
  })

// 我的下载-源文件
export const myDownloadedSourceFileList = (params) =>
  service({
    url: '/api/personalCenter/dowmloadOriginList',
    method: 'get',
    params,
  })

// 我的上传-批量删除文件
export const fileRemove = (params) =>
  service({
    url: '/api/search/remove',
    method: 'get',
    params,
  })

// 文件评论
export const addFileFeedbackApi = (data) =>
  service({
    url: '/api/fileFeedback/addFileFeedback',
    method: 'post',
    data,
  })

// 获取文件评论
export const queryFileFeedbackApi = (params) =>
  service({
    url: '/api/fileFeedback/queryFileFeedback',
    method: 'get',
    params,
  })

// 视频字幕、文档翻译，对接AI
export const getTranslateListApi = (data) =>
  service({
    url: '/api/aiInterface/getTranslateList',
    method: 'post',
    data,
  })

// 刪除AI标签
export const deleteAiTagByIdApi = (data) =>
  service({
    url: '/api/tag/deleteAiTagById',
    method: 'post',
    data,
  })

// 获取link数据
export const getResourcePopDataApi = (params) =>
  service({
    url: '/api/search/getResourcePopData',
    method: 'get',
    params,
  })
